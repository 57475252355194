import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import MiniPOSForm from "../components/sections/merchants/application";
import Header from "../components/sections/merchants/header";
import Footer from "../components/sections/footer";
import Inquiries from "../components/sections/home/inquiries";
import WhyPOS from "../components/sections/why-pos";
import MerchantBenefits from "../components/sections/merchants/benefits";

const MiniPOS = () => {
  return (
    <Layout>
      <SEO
        title="OPay Mini POS"
        description="Your best partner for accepting payments from your customers and banking services on the go!"
      />
      <Navigation background="white" />
      <Header />
      <MerchantBenefits />
      <MiniPOSForm />
      <WhyPOS />
      <Inquiries />
      <Footer />
    </Layout>
  );
};

export default MiniPOS;
